@content13: content13;
.@{content13}-wrapper {
  min-height: 380px;
  background: url("https://gocn.oss-cn-shanghai.aliyuncs.com/photo/wbofeng/93776905-6735-49e3-a9b5-9a8a1210e93a.png?x-oss-process=image%2Fresize%2Cw_1920") no-repeat;
  background-position: 50% 80%;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
    }
  }
  .title-content {
    font-weight: 600;
    line-height: 32px;
  }
}

@media screen and (max-width: 767px) {
  .@{content13}-wrapper {
    padding-bottom: 0;
  }
}
